import i18n from '@/plugins/VueI18n';
import ErrorService from '@/utils/ErrorService';
import filesize from 'filesize';
import gsap from 'gsap';
import MoneyBadger from '@/utils/MoneyBadger';
import FileEntry from './FileEntry';
import FileEntryStatus from './FileEntryStatus';
import IUploader from './IUploader';
import IUploaderOptions from './IUploaderOptions';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import ITransformedValue from '@/ship/Values/ITransformedValue';

const serverAcceptMimeTypes = [/^image\/.+$/, /^application\/vnd\..*$/, /^application\/msword$/, /^application\/pdf$/];

export default class Uploader<_Ty = any> implements IUploader<_Ty> {
    public static createEntriesFromFiles(files: File | File[] | FileList) {
        if (files instanceof File) {
            return [new FileEntry(files)];
        } else {
            return Array.from(files).map((file) => new FileEntry(file));
        }
    }

    private _options: IUploaderOptions;

    private _acceptMimeTypes: RegExp[] = [];

    constructor(options: IUploaderOptions) {
        this._options = options;

        if (this._options && this._options.accept) {
            this._acceptMimeTypes = this.parseAcceptToMatch(this._options.accept);
        }
    }

    public async upload(entries: FileEntry<_Ty> | Array<FileEntry<_Ty>>): Promise<FileEntry[]> {
        entries = Array.isArray(entries) ? entries : [entries];

        for (const entry of entries) {
            // Gets the request data.
            const requestData = this.getRequestData(entry);

            // Sets the stated the status.
            entry.status = FileEntryStatus.UPLOADING;

            // If a file size exceeds an allowed size then throws an error for this file.
            if (!this.isAllowedFileSize(entry)) {
                entry.status = FileEntryStatus.ERROR;
                entry.errorMessage = i18n.t('errors.fileTooLarge', {
                    maxFileSize: filesize(this._options!.maxSize!),
                });

                continue;
            }

            // If a file mime-type is not allowed then throws an error for this file.
            if (!this.isAllowedFileMimeType(entry)) {
                entry.status = FileEntryStatus.ERROR;
                entry.errorMessage = i18n.t('errors.notAllowedFileType');

                continue;
            }

            try {
                const options = {
                    headers: this._options.headers,
                    onUploadProgress: this.progressHandler(entry),
                };
                const endpoint = this._options.endpoint;
                const response = await this.uploadWithUrl(endpoint, requestData, options);

                entry.response = response;
                entry.responseModel = response.data.data;

                // Sets the status that the upload has succeeded.
                entry.status = FileEntryStatus.SUCCESSFUL;
                entry.progress = 100;
            } catch (error: any) {
                entry.status = FileEntryStatus.ERROR;
                entry.errorMessage = ErrorService.errorMessage(error, 'errors.failedToUpload');
            }
        }

        return entries;
    }

    /**
     * Uploads a file using the specified url.
     *
     * @returns Uploaded file.
     */
    private uploadWithUrl(
        url: string,
        data: FormData,
        options: AxiosRequestConfig = {},
    ): Promise<AxiosResponse<ITransformedValue<_Ty>>> {
        const instance = this._options.instance;
        return instance.post<FormData, AxiosResponse<ITransformedValue<_Ty>>>(url, data, options);
    }

    /**
     * Converts string or array to regex.
     *
     * @param accept Accepted file mime-types.
     */
    private parseAcceptToMatch(accept: string | string[]): RegExp[] {
        // If accept is string then splits to array.
        if (typeof accept === 'string') {
            accept = accept.split(/,\s/);
        }

        return accept.map((match) => {
            // Replaces the * character with any character.
            // it's necessary to support the accept-input format.
            match = `^${match}$`.replace('*', '.*');

            return new RegExp(match);
        });
    }

    private isAllowedFileSize(entry: FileEntry): boolean {
        return this._options?.maxSize ? entry.size < this._options.maxSize : true;
    }

    private isAllowedFileMimeType(entry: FileEntry): boolean {
        const mimeType = entry.type;
        const acceptMimeTypes = this._acceptMimeTypes;

        // Firstly checks a file is allowed for the currently settings, and then
        // check if the file allowed to upload to the server.
        return this.matchMimeType(mimeType, acceptMimeTypes) && this.matchMimeType(mimeType, serverAcceptMimeTypes);
    }

    private matchMimeType(mimeType: string, matches: RegExp[]) {
        if (matches.length === 0) {
            return true;
        }

        for (const match of matches) {
            if (mimeType.match(match)) {
                return true;
            }
        }

        return false;
    }

    private progressHandler(entry: FileEntry) {
        return (progressEvent: { loaded: number; total: number }) => {
            const total = progressEvent.total;
            const loaded = progressEvent.loaded;

            // Gets the progress of the upload process.
            const progress = MoneyBadger.getProgress(loaded, total);

            // Removes 5% because file will be uploaded to a server but server needs time to save the file.
            gsap.to(entry, { progress: progress - 5, direction: 1 });
        };
    }

    private getRequestData(entry: FileEntry): FormData {
        const data = new FormData();
        const fieldName = this._options.fieldName!;

        data.append(fieldName, entry.file!);

        if (this._options.data) {
            const props = this._options.data;

            Object.keys(props).forEach((key: string) => {
                data.append(key, props[key]);
            });
        }

        return data;
    }
}
