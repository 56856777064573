import { AxiosResponse } from 'axios';
import { TranslateResult } from 'vue-i18n';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import FileEntryStatus from './FileEntryStatus';

export default class FileEntry<_Ty = any> {
    public file?: File;
    public name: string;
    public size: number;
    public type: string;

    public response?: AxiosResponse<ITransformedValue<_Ty>>;
    public responseModel?: _Ty;
    public status: FileEntryStatus = FileEntryStatus.WAITING;
    public progress = 0;
    public errorMessage?: string | TranslateResult;
    public browsableImageData?: string | ArrayBuffer | null = null;

    constructor(file: File) {
        this.file = file;
        this.name = this.file.name;
        this.size = this.file.size;
        this.type = this.file.type;
    }
}
